import axios from "axios";
import qs from "qs";
import env from '@/config/index'
import {getItem,setItem} from '@/utils/utils'
import {TOKEN} from '@/const/index'

axios.defaults.baseURL = env.baseUrl

//post请求头
axios.defaults.headers.post["Content-Type"] = "application/x-www-form-urlencoded;charset=UTF-8";
//允许跨域携带cookie信息
axios.defaults.withCredentials = true;
//设置超时
axios.defaults.timeout = 150000;
// 请求拦截
axios.interceptors.request.use(
    config => {
      // config.headers['Authorization'] = 'WkU3ais0eVdwR0plcmFpRklxNjZPVGZjSGMwUmhGSFpHMTBHVXFFQTV0OD0='// 用户登录后返回的Authorization
      config.headers[TOKEN] = getItem(TOKEN) // 用户登录后返回的Authorization
      if (config.method === 'post') {
        if (!config.data) { // 没有参数时，config.data为null，需要转下类型
          config.data = {}
        }
      }
      return config;
    },
    error => {
        return Promise.reject(error);
    }
);
// 响应拦截
axios.interceptors.response.use(
    response => {
        if (response.status == 200) {
          if(getItem(TOKEN) && response.data.code == -1){
            setItem(TOKEN,null)
            window.location.reload()  //如果未登录 等于-1 刷新页面
          }
          return Promise.resolve(response);
        } else {
            return Promise.reject(response);
        }
    },
    error => {
      return Promise.reject(error);
    }
);
export default {
    /**
     * @param {String} url
     * @param {Object} data
     * @returns Promise
     */
    post(url, data, responseType) {
        return new Promise((resolve, reject) => {
            axios({
                    method: 'post',
                    url,
                    responseType:responseType?'blob':'json',
                    data: data,
                })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((err) => {
                    reject(err)
                });
        })
    },

    get(url, data, responseType) {
        return new Promise((resolve, reject) => {
            axios({
                    method: 'get',
                    url,
                    responseType:responseType?'blob':'json',
                    params: data,
                })
                .then(res => {
                  resolve(res)
                })
                .catch(err => {
                  reject(err)
                })
        })
    }
};
